<template>
  <div style="height: 100%;" >
    <vue-headful :title="pageTitle" />

    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1 class="is-size-6 has-text-white" style="padding: 5px 0px">
        {{ pageheading.toLocaleUpperCase() }}
      </h1>
    </div>

    <Loading v-if="isLoading"/>
    <div style="height: 100%; width: 100%!important;" id="body-content-area" v-else>
      <div id="pagelayout" class="page-wrapper">
        <div class="button-group">
          <button :disabled="(isNotNew && isSame) || !$hasPermissions(clientSession, ['PERMISSION_TEMPLATES'], 2)" class="button is-accent" @click="submit">
            Save
          </button>
          <button @click="$router.back()" class="button is-dark" type="button">
            Cancel
          </button>
        </div>
        <div class="columns">
          <div class="column column-is-one-half">
            <div
                class="block"
                style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray;"
              >
                <span class="has-text-weight-bold">Permission Template</span>
              </div>
              <div class="control">
                <label for="" class="label is-size-7">Name</label>
                <input type="text" class="input" v-model="input.name" />
              </div>

              <div class="control">
                <label for="" class="label is-size-7">Description</label>
                <textarea maxlength="300" rows="5" type="text" class="textarea" v-model="input.description" />
                <span
                  :style="{
                    justifyContent: 'flex-end',
                    width: '100%!important',
                    display: 'flex',
                    opacity: inputOpacity,
                    color: $inputColorScale(input.description ? input.description.length : 0, [
                    {
                      color: '#7fb942',
                      min: 0,
                      max: 200
                    },
                    {
                      color: '#FFD300',
                      min: 200,
                      max: 225
                    },
                    {
                      color: '#ff9200',
                      min: 225,
                      max: 275
                    },
                    {
                      color: '#e82b18',
                      min: 275,
                      max: 301
                    }
                    ])
                  }"
                >
                {{input.description ? input.description.length : 0}}&nbsp;<div style="color: black; black"> / 300</div>
                </span>
              </div>
          </div>
          <div class="column column-is-one-half" style="padding-bottom: 5rem; max-height: 75vh; overflow: hidden; overflow-y: scroll;">
            <div
                class="block"
                style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray;"
              >
                <span class="has-text-weight-bold">Default Permissions</span>
              </div>
              <table class="table" style="width: 100%;" v-if="input.id !== undefined">
                <tr>
                  <th colspan="3">Section</th>
                  <th style="width: 100px">No-Access</th>
                  <th style="width: 100px">Read-Only</th>
                  <th style="width: 100px">Read/Write</th>
                </tr>
                <tr v-for="permission in permissions" :key="permission.code" @mouseup="isSame = false">
                  <td colspan="3" style="color: #363636!important;">{{permission.name}}</td>

                  <td>
                    <input type="radio" :name="permission.code" :value="0" v-model="input.permissions[permission.code]"/>
                  </td>
                  <td>
                    <input type="radio" :name="permission.code" :value="1" v-model="input.permissions[permission.code]" />
                  </td>
                  <td>
                    <input type="radio" :name="permission.code" :value="2" v-model="input.permissions[permission.code]" />
                  </td>
                </tr>
              </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable eqeqeq */
import { mapState } from 'vuex'
import Loading from './components/Loading.vue'
import moment from 'moment-timezone'

// TODO - move to an API endpoint so that these permissions are dynamic. Currently they're only defined in code
// and DB / API accessors aren't really needed. Just a nice to have.
const permissions = [
  {
    id: 0,
    name: 'Client Reports',
    code: 'CLIENT_REPORTS'
  },
  {
    id: 1,
    name: 'Document Center',
    code: 'DOCUMENT_CENTER'
  },
  {
    id: 2,
    name: 'Announcements',
    code: 'ANNOUNCEMENTS'
  },
  {
    id: 3,
    name: 'Email Templates',
    code: 'EMAIL_TEMPLATES'
  },
  {
    id: 4,
    name: 'Carriers',
    code: 'CARRIERS'
  },
  {
    id: 5,
    name: 'Institutions',
    code: 'INSTITUTIONS'
  },
  {
    id: 6,
    name: 'Report Types',
    code: 'REPORT_TYPES'
  },
  {
    id: 7,
    name: 'Client Menu',
    code: 'CLIENT_MENU'
  },
  {
    id: 8,
    name: 'Sites',
    code: 'SITES'
  },
  {
    id: 9,
    name: 'Permission Templates',
    code: 'PERMISSION_TEMPLATES'
  },
  {
    id: 10,
    name: 'Users',
    code: 'USERS'
  },
  {
    id: 11,
    name: 'Activity Report',
    code: 'ACTIVITY_REPORT'
  },
  {
    id: 12,
    name: 'Asset Report',
    code: 'ASSET_REPORT'
  },
  {
    id: 14,
    name: 'Report Configuration',
    code: 'REPORT_CONFIGURATION'
  },
  {
    id: 15,
    name: '8925 Report',
    code: 'C8925_REPORT'
  },
  {
    id: 16,
    name: 'Summary Asset Report',
    code: 'ASSET_SUMMARY_REPORT'
  },
  {
    id: 17,
    name: 'Annual Questionnaire',
    code: 'ANNUAL_QUESTIONNAIRE'
  },
  {
    id: 18,
    name: 'Annual BOLI Review',
    code: 'ANNUAL_BOLI_REVIEW'
  }
]

export default {
  components: {
    Loading: Loading
  },
  data () {
    return {
      pageheading: '',
      isLoading: true,
      permissions: permissions,
      model: {},
      isSame: true,
      input: {
        permissions: {
          client_reports: 0
        }
      }
    }
  },
  watch: {
    'input': {
      handler (val) {
        this.isSameCheck()
      },
      deep: true
    }
  },
  computed: {
    ...mapState(['activeSite', 'clientSession']),
    pageTitle () {
      return 'Permission Templates - ' + this.activeSite.displayName
    },
    isNotNew () {
      return this.$route.params.id && this.$route.params.id !== 'new'
    },
    inputOpacity () {
      const descriptionLength = this.input.description ? this.input.description.length : 0

      if (descriptionLength > 300) {
        return '1'
      } else if (descriptionLength > 250) {
        return '0.8'
      } else if (descriptionLength > 200) {
        return '0.6'
      } else {
        return '0.5'
      }
    },
    inputColor () {
      const descriptionLength = this.input.description ? this.input.description.length : 0

      if (descriptionLength > 280) {
        return 'red'
      } else if (descriptionLength > 250) {
        return 'orange'
      } else {
        return '#000'
      }
    },
    isNotEdited () {
      return this.isSame()
    }
  },
  mounted () {
    this.setup()
  },
  methods: {
    isSameCheck () {
      let nameAndDescriptionIsSame = this.input.name === this.model.name && this.input.description === this.model.description
      let permissionsAreSame = true

      if (this.input.permissions) {
        Object.keys(this.input.permissions).map(permission => {
          if (this.input.permissions[permission] !== this.model.permissions[permission]) {
            permissionsAreSame = false
          }
        })

        //  Fill in missing template permissions
        for (let permission of permissions) {
          if (this.input.permissions[permission.code] === undefined) {
            this.input.permissions[permission.code] = 0 // Set to no access by default
          }
        }
      }

      this.isSame = nameAndDescriptionIsSame && permissionsAreSame
    },
    moment (date) {
      return moment(date)
    },
    async submit () {
      if (this.isNotNew) {
        this.updatePermissionTemplate()
      } else {
        this.createPermissionTemplate()
      }
    },
    async updatePermissionTemplate () {
      this.isLoading = true
      const response = await this.$http.put(`/permission-templates/${this.$route.params.id}`, this.input)
      if (response.status === 200) {
        this.successToast('Permission Template Updated')
        this.$router.push({ name: 'PermissionTemplate' })
      } else {
        this.errorToast('Error Updating Permission Template')
      }
      this.isLoading = false
    },
    async createPermissionTemplate () {
      this.isLoading = true
      let response = await this.api().permissions().createPermissionTemplate(this.input)
      if (response.status === 200) {
        this.successToast('Permission Template Created')
        this.$router.push({ name: 'PermissionTemplate' })
      } else {
        this.errorToast(response.data && response.data.error ? response.data.error : 'Error creating permission template.')
      }
      this.isLoading = false
    },
    async getPermissionTemplate () {
      this.isLoading = true
      // let response = await this.$http.get('/api/permission-templates/' + this.$route.params.id)
      let response = await this.api().permissions().getPermissionTemplate(this.$route.params.id)
      if (response.status === 200) {
        // <!-- console.log(this.input) -->
        this.input = { ...response.data.data }
        if (this.input) {
          this.input.permissions = {}

          Object.keys(response.data.data.permissions).map(permission => {
            this.input.permissions[permission] = response.data.data.permissions[permission]
          })

          this.model = { ...this.input }
        }
        this.isLoading = false
      } else {
        this.isLoading = false
        this.$errorToast('Error', 'Error getting permission template')
      }
    },
    async setup () {
      this.isLoading = true

      if (this.isNotNew) {
        this.pageheading = 'Permission Template Details'
        await this.getPermissionTemplate()
      } else {
        this.pageheading = 'New Permission Template'
        this.input = {
          id: 0,
          name: '',
          description: '',
          permissions: {}
        }

        this.permissions.map(type => {
          this.input.permissions[type.code] = 0
        })
      }

      this.isLoading = false
    }
  }
}
</script>
